import React, { useEffect, useState } from "react"
import { Button, Card, CircularProgress, Grid, makeStyles, TextField, Typography } from "@material-ui/core"
import { navigate } from "@reach/router"
import PhoneInput from "react-phone-input-2"
import { API_ADDRESS, createEnum, PHONE_REGEX, EMAIL_REGEX } from "../lib/constants"
import axios from "axios"
import { isAuthenticated } from "../lib/auth"
import SharedStyles from '../components/shared_styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { Alert } from "@material-ui/lab"

const PHONE_FORM_STATE = createEnum(['NORMAL', 'LOADING', 'SUCCESS']);
const EMAIL = 'email';

const useStyles = makeStyles(theme => ({
  getStartedButton: {
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      height: '56px'
    }
  },
  phoneInput: {
    // Need important to override the plugin's default style.
    height: '48px !important',
    width: '100% !important',
    [theme.breakpoints.down('sm')]: {
      height: '56px !important',
    }
  }
}));


const GetStartedPhone = ({inviterCode, networkKey, fullWidth, phoneDefault, newUserButtonLabel, center, isEmail}) => {
  const styles = SharedStyles();
  const classes = useStyles();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [displayState, setDisplayState] = useState(PHONE_FORM_STATE.NORMAL);
  const [localIsAuthenticated, setLocalIsAuthenticated] = React.useState(false);

  useEffect(() => {
    setLocalIsAuthenticated(isAuthenticated());
    if (phoneDefault) {
      setPhone(phoneDefault)
    }
  },[]);

  const handlePhoneChange = (inputPhone) => {
    setPhone(inputPhone);
  }
  const handleTextField = event => {
    setEmail(event.target.value);
  }

  const submitPhoneNumber = (event) => {
    const cleanPhone = phone.replace(/[^0-9|+]/g, '');
    const data = {};
    if(isEmail) {
      if(!EMAIL_REGEX.test(email)) {
        return alert("Please enter a valid email address.") ;
      }
      data['email'] = email;
    } else {
      if (!PHONE_REGEX.test(cleanPhone)) {
        return alert("Please enter a valid phone number.");
      }
      data['phone'] = cleanPhone;
    }

    setDisplayState(PHONE_FORM_STATE.LOADING);
    if (inviterCode) {
      data['inviter'] = inviterCode;
    }
    if (networkKey) {
      data['networkKey'] = networkKey;
    }
    axios.post(`${API_ADDRESS}/user/start`,data)
      .then((response)=>{
        if (response.data.error) {
          // TODO: Modify the phone form state based on the error. For now just
          // alert.
          if (response.data.error_message) {
            alert(response.data.error_message);
            setDisplayState(PHONE_FORM_STATE.NORMAL);
          }
        } else {
          setDisplayState(PHONE_FORM_STATE.SUCCESS);
        }
      });
  }


  if (localIsAuthenticated) {
    return (
      <Grid container item xs={12} justify={center?"center":"flex-start"} alignItems="center">
        <Grid item xs={12} sm={!fullWidth && 6}>
          <Button
            variant="contained"
            color="primary"
            className={classes.getStartedButton}
            onClick={() => navigate('/app')}
            fullWidth>
            Go to your account

          </Button>
        </Grid>
      </Grid>
    );
  }
  if (displayState == PHONE_FORM_STATE.SUCCESS) {
    return (
      <Grid container item xs={12} justify="center" alignItems="center">
        <Grid item xs={12} sm={!fullWidth && 6}>
          <Alert severity="success" style={{width: '100%'}}>
            Check your {isEmail?'email':'phone'} for next steps.
          </Alert>
        </Grid>
      </Grid>
    );
  }

  let buttonContent;
  if (displayState == PHONE_FORM_STATE.NORMAL) {
    buttonContent = newUserButtonLabel || 'Join Greenwoodx';
  } else {
    buttonContent = <CircularProgress size={24} />;
  }
  return (
    <Grid container item xs={12} justify={center?"center":"flex-start"} alignItems="center" spacing={2} style={{margin:0}}>
      <Grid item xs={12} sm={12} md={!fullWidth && 7} >
        {!isEmail &&
          <PhoneInput
            inputProps={{
              name: "phone",
              id: "phone"
            }}
            inputClass={classes.phoneInput}
            onlyCountries={['us']}
            country={'us'}
            disableDropdown
            value={phone}
            onChange={handlePhoneChange}
            placeholder="Enter your phone number"
          />
        }
        {isEmail &&
          <TextField
            id={EMAIL}
            name={EMAIL}
            variant="outlined"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleTextField}
            required
            margin="dense"
            error={email.length > 1 && !EMAIL_REGEX.test(email)}
          />
        }
      </Grid>
      <Grid item xs={12} sm={12} md={!fullWidth && 5}>
        <Button
          color="primary"
          className={clsx([classes.getStartedButton])}
          variant="outlined"
          onClick={submitPhoneNumber}
          fullWidth
          disabled={displayState != PHONE_FORM_STATE.NORMAL}
          style={{background: 'white'}}
        >
          {buttonContent}
        </Button>
      </Grid>
      <Grid item xs={12}>
        Already a member? <a href="#" onClick={()=>navigate('/login')}>Login here</a>
      </Grid>
    </Grid>
  );
}

export default GetStartedPhone;
