import React, {useEffect, useState} from 'react'
import { Router, navigate } from '@reach/router'
import SEO from "../seo"
import { ElementOrLoader } from "../util"
import { createEnum, NOTIFICATION_LAYER } from "../../lib/constants"

import clsx from 'clsx'
import {
  Container,
  Box,
  Typography,
  Paper, Chip, Stepper, Step, StepLabel, Grid, TextField,

  List,
  ListItem,
  ListItemIcon,
  ListItemText, Button, CircularProgress, CssBaseline, ThemeProvider,
  makeStyles, Link, FormLabel,
} from "@material-ui/core"
import {
  Alert,
} from '@material-ui/lab';
import Layout from "../layout"
import SharedStyles from '../shared_styles'
import GetStartedPhone from "../get_started_phone"
import {apiPost, apiGet} from "../../lib/api"

const PASSPHRASE = 'passphrase';

const useStyles = makeStyles((theme) =>({
  page: {
    display: 'flex',
    height: '100vh',
    backgroundImage: "linear-gradient(0deg, #673AB7 6%, #794CC9 34%)"
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    // margin: '0 auto',
    // height: '100%',
    overflow: 'hidden',
    marginBottom: theme.spacing(3)
  },
  innerPadding: {
    padding: theme.spacing(5)
  },

  logo: {
    width: '270px',
    height: '90px',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
}));

const firstLoad = {
  name: 'Greenwoodx',
  logo: 'https://greenwoodx.s3.us-east-2.amazonaws.com/purple_logo.svg',
  blurb: 'Connecting 1000 underrepresented students to professionals',
}

const STEPS = createEnum(['PASSPHRASE', 'SIGNUP', 'LOGIN', 'LOADING', 'SENT']);

const Join = ({handle}) => {
  const classes = useStyles();
  const styles = SharedStyles();

  const [step, setStep] = useState(STEPS.PASSPHRASE);
  const [passphrase, setPassphrase] = useState('');
  const [loading, setLoading] = useState(true);
  const [stepLoading, setStepLoading] = useState(false);

  const [networkInfo, setNetworkInfo] = useState({});
  const [networkKey, setNetworkKey] = useState('');
  const [emailSignup, setEmailSignup] = useState(false);

  useEffect(() => {
    apiGet(`private-network/get-info`, {handle: handle})
      .then((data) => {
        if (data.error) {
          alert(data.error_message);
          return navigate('/');
        }
        setNetworkInfo(data.info);
        if (!data.info.HasPassphrase) {
          setStep(STEPS.SIGNUP);
          checkPassphrase();
        }
        setLoading(false);
      })
  }, [])

  const handleTextField = (event) => {
    switch(event.target.name) {
      case PASSPHRASE:
        setPassphrase(event.target.value);
        break;
    }
  }

  const checkPassphrase = () => {
    setStepLoading(true);
    const data = {
      handle:handle,
      passphrase: passphrase.length>0?passphrase:null
    };
    apiPost('private-network/check-passphrase', data)
      .then((response) => {
        if (response.error) {
          return alert(response.error_message);
        }
        setNetworkKey(response.info.Key);
        setEmailSignup(response.info.NotificationLayer == NOTIFICATION_LAYER.EMAIL);
        setStep(STEPS.SIGNUP);
      })
      .then(()=>{
        setStepLoading(false);
      })

  }

  const getPassphrase = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            id={PASSPHRASE}
            name={PASSPHRASE}
            label="Enter the passphrase"
            variant="outlined"
            fullWidth
            required
            value={passphrase}
            onChange={handleTextField}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} className={styles.centerElements}>
          <Button
            onClick={checkPassphrase}
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
        </Grid>
      </React.Fragment>
    );
  }

  const getSignup = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          {networkInfo.HasPassphrase &&
            <Alert severity="success" style={{ width: '100%' }}>
              Correct passphrase.
            </Alert>
          }
        </Grid>
        <Grid item xs={12} style={{padding:0}}>
          <GetStartedPhone fullWidth isEmail={emailSignup} networkKey={networkKey}/>
        </Grid>
        <Grid item xs={12} style={{padding:0, marginTop: '20px'}} className={styles.centerText}>
          <Typography variant="caption">
            Already have an account? <Link onClick={()=>navigate('/login')}>Login</Link>
          </Typography>
        </Grid>

      </React.Fragment>
    );
  }

  const getBody = () => {
    switch (step) {
      case STEPS.PASSPHRASE:
        return getPassphrase();
      case STEPS.SIGNUP:
        return getSignup();
      default:
        return null;
    }
  }


  return (
    <Layout showHeader={false} showFooter={false}>
      <Box className={classes.page} >
        <Container maxWidth="xs" className={styles.centerColumnElements}>
          <Paper className={classes.paper} elevation={1} >
            <Box className={clsx([classes.innerPadding, styles.centerColumnElements])}>
              <ElementOrLoader loading={loading}>
                <SEO title={`Join ${networkInfo.Name}`} />
                <Grid container spacing={5}>
                   <Grid item xs={12} className={styles.centerElements} style={{padding:0}}>
                    <Box className={classes.logo} style={{backgroundImage:`url('${networkInfo.Logo}')`}} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={styles.centerText}>
                      {networkInfo.Blurb}
                    </Typography>
                  </Grid>
                  <ElementOrLoader loading={stepLoading}>
                    {getBody()}
                  </ElementOrLoader>
                </Grid>
              </ElementOrLoader>
            </Box>
          </Paper>
          <Typography>Powered by Greenwoodx</Typography>
        </Container>
      </Box>
    </Layout>
  );
}

export default Join;
