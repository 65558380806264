import React from 'react'
import { Router, navigate } from '@reach/router'

import Join from "../components/b2b/join"

const JoinWrapper = (props) => {
  return (
    <Router>
      <Join
        {...props}
        path="/join/:handle"/>
    </Router>
  );
}

export default JoinWrapper;
